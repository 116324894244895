import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import { useSelector } from 'react-redux'

import API from '../../services/api'
import { usePrevious } from '../../utils'
import { StaticScopeMetadata } from '../../lib/scope'

import {
  RiArrowUpSLine,
  RiArrowDownSLine,
  RiStickyNote2Line,
  RiStickyNoteLine
} from 'react-icons/ri'

import * as styles from './style.module.scss'
import { PRIMARY_COLOR } from '../../config/env'

const CustomWindow = ({
  title,
  subtitle,
  showNote,
  name,
  children,
  defaultOpen
}) => {
  const [hide, setHide] = useState(!defaultOpen)
  const [noteOpened, setNoteOpened] = useState(false)
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [locked, setLocked] = useState(false)

  const documento = useSelector(state => state.globalStep.targetDocument)
  const prevDocumento = usePrevious(documento)
  const prevName = usePrevious(name)

  const getComment = useCallback(() => {
    return API.comments
      .get({ documento, campo: name })
      .then(data => {
        if (data.locked) {
          setLocked(true)
        } else {
          setComment(data.blob[0].value)
          return API.comments.lock({
            documento,
            campo: name
          })
        }
      })
      .catch(err => {
        if (err.response?.status === 404) {
          return API.comments.lock({ documento, campo: name }).catch(err2 => {
            if (err2.response?.status === 502) {
              setLocked(true)
            } else {
              console.error(err2)
            }
          })
        } else {
          setError(true)
          console.error(err)
        }
      })
  }, [documento, name])

  useEffect(() => {
    if (prevDocumento !== documento || prevName === name) {
      setComment('')
      setError(false)
      setLoading(false)
      setLocked(false)
    }
  }, [documento, prevDocumento, name, prevName])

  const toggleNote = useCallback(() => {
    if (noteOpened && !locked) {
      if (!locked) {
        API.comments
          .unlock({ documento, campo: name })
          .catch(err => console.error(err))
        setLocked(false)
      }
      setError(false)
      setLoading(false)
    } else {
      getComment().finally(() => setLoading(false))
    }
    setNoteOpened(prev => !prev)
  }, [documento, name, noteOpened, locked, getComment])

  const handleSave = useCallback(async () => {
    setLoading(true)
    await API.comments
      .save({
        texto: comment,
        documento,
        campo: name
      })
      .then(() => {
        toast.success('Comentário salvo!', {
          position: toast.POSITION.BOTTOM_RIGHT
        })
        return API.comments.lock({
          documento,
          campo: name
        })
      })
      .catch(e => console.error(e))
      .finally(() => setLoading(false))
  }, [comment, documento, name])

  const handleUnlock = useCallback(async () => {
    setLoading(true)
    await API.comments
      .unlock({ documento, campo: name })
      .then(() => setLocked(false))
      .then(() => getComment())
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }, [documento, name, getComment])

  const renderLoading = () => {
    return <p>Carregando</p>
  }

  const renderError = () => {
    return <p>Erro ao carregar comentário</p>
  }

  const renderLock = () => {
    return (
      <div>
        <span>
          O arquivo pode estar sendo editado por outra pessoa. As informações
          poderão ser perdidas, quer editar mesmo assim?
        </span>
        <div className='d-flex'>
          <button
            type='button'
            className='btn btn-warning mt-1 mx-auto'
            onClick={handleUnlock}
          >
            Editar
          </button>
        </div>
      </div>
    )
  }

  const renderLoaded = () => {
    return (
      <div>
        <p>Você deve incluir nome e data no início do comentário!</p>
        <textarea
          className='form-control w-100'
          placeholder='Faça suas notas aqui'
          value={comment}
          onChange={event => setComment(event.target.value)}
        />
        <div className='d-flex mb-2'>
          <button
            type='button'
            className='btn btn-primary mt-2 ml-auto'
            onClick={handleSave}
          >
            Salvar
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={`my-4 ${styles.rootContainer}`}>
      <Header
        title={title}
        subtitle={subtitle}
        opened={!hide}
        showNote={
          showNote &&
          StaticScopeMetadata.getAvailableFeatures()?.commentsInAnalysis
        }
        onToggle={() => setHide(prev => !prev)}
        onToggleNote={() => toggleNote()}
        disabled={!documento}
      />
      <ReactTooltip />
      {!hide && (
        <div className='container-fluid py-2'>
          {noteOpened && (
            <div className='row'>
              <div className='col-12'>
                {error
                  ? renderError()
                  : loading
                  ? renderLoading()
                  : locked
                  ? renderLock()
                  : renderLoaded()}
              </div>
            </div>
          )}
          {children}
        </div>
      )}
    </div>
  )
}

const Header = ({
  title,
  subtitle,
  opened,
  onToggle,
  onToggleNote,
  showNote,
  noteOpened,
  disabled
}) => {
  return (
    <div className={`px-3 py-2 d-flex flex-row ${styles.headerContainer}`}>
      <div className='d-flex flex-column flex-grow-1'>
        <span className={`flex-grow-1 ${styles.title}`}>{title}</span>
        <span className={`flex-grow-1 ${styles.subtitle}`}>{subtitle}</span>
      </div>
      <div className='d-flex flex-row justify-content-center'>
        {showNote && opened && (
          <button
            className='btn'
            disabled={disabled}
            data-tip={noteOpened ? 'Fechar notas' : 'Abrir notas'}
            onClick={onToggleNote}
          >
            {noteOpened ? (
              <RiStickyNote2Line color={PRIMARY_COLOR} />
            ) : (
              <RiStickyNoteLine color={PRIMARY_COLOR} />
            )}
          </button>
        )}
        <button className='btn' disabled={disabled} onClick={() => onToggle()}>
          {opened ? (
            <RiArrowUpSLine color={PRIMARY_COLOR} />
          ) : (
            <RiArrowDownSLine color={PRIMARY_COLOR} />
          )}
        </button>
      </div>
    </div>
  )
}

export default CustomWindow
