import { toast } from 'react-toastify'
import { call, put, select } from 'redux-saga/effects'

import {
  BANDEIRA_AMARELA_PROVIDER_NAME,
  BASIC_INFO_PROVIDER_NAME,
  BOANOTA_PROVIDER_NAME,
  JURIDICO_PROVIDER_NAME,
  OFFSHORE_PROVIDER_NAME,
  SPREAD_PROVIDER_NAME,
  OPERACIONAIS_PROVIDER_NAME,
  PATRIMONIO_PROVIDER_NAME,
  ENDIVIDAMENTO_PROVIDER_NAME,
  VISITAS_PROVIDER_NAME,
  LOGSUL_PROVIDER_NAME,
  MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME,
  DISCREDITING_MEDIA_PROVIDER_NAME
} from '../../config/stepFunction'

import { PROGRESS_TYPES } from '../../lib/progressStatus'
import { fetchDadosBasicosFromS3 } from '../../lib/s3Theme'
import { StaticScopeMetadata } from '../../lib/scope'
import { SQSConsumer } from '../../lib/sqs'

import { getCurrentUser, getIdToken } from '../../utils'

import API from '../../services/api'

import { resolveDictWithLabels } from '../selectors/progressStatus'
import {
  setEmpresasSociosGlobalStep,
  setErrorGlobalStep,
  setMetadataGlobalStep,
  setQueueUrlGlobalStep,
  setTargetDocumentGlobalStep,
  setTargetNameGlobalStep,
  setTargetSerasaGlobalStep
} from '../store/globalStep'
import { setStatusProgressStatus } from '../store/progressStatus'

export function * startGlobalStep (action) {
  try {
    const {
      documento: document,
      enableSerasa,
      enablePartners,
      spreadType
    } = action.payload

    yield put(setTargetDocumentGlobalStep(document))

    const { queueUrl } = yield call(API.sqs.create, { documento: document })

    yield put(setQueueUrlGlobalStep(queueUrl))

    const accessToken = yield call(getIdToken)

    const consumer = yield call(SQSConsumer.getInstance, { accessToken })

    if (consumer.running) {
      consumer.stop()
    }

    const stepsToListen = [
      BOANOTA_PROVIDER_NAME,
      BASIC_INFO_PROVIDER_NAME,
      ENDIVIDAMENTO_PROVIDER_NAME,
      BANDEIRA_AMARELA_PROVIDER_NAME,
      OFFSHORE_PROVIDER_NAME,
      PATRIMONIO_PROVIDER_NAME,
      JURIDICO_PROVIDER_NAME,
      SPREAD_PROVIDER_NAME,
      VISITAS_PROVIDER_NAME,
      LOGSUL_PROVIDER_NAME,
      OPERACIONAIS_PROVIDER_NAME,
      MATCH_GRUPO_SOCIETARIO_PROVIDER_NAME,
      DISCREDITING_MEDIA_PROVIDER_NAME
    ]

    stepsToListen
      .filter(step => StaticScopeMetadata.getAvailableSteps().includes(step))
      .forEach(step => {
        consumer.addProvider(step)
      })

    const username = yield call(getCurrentUser)

    yield put(setTargetSerasaGlobalStep(enableSerasa))

    const { executionArn, startDate } = yield call(API.rootStep.start, {
      username,
      document,
      enableSerasa,
      enablePartners,
      spreadType,
      queueUrl
    })

    yield put(
      setMetadataGlobalStep({
        executionArn,
        startedAt: new Date(startDate)
      })
    )

    yield call(startConsume, { consumer })

    yield put(setStatusProgressStatus(resolveInitialState()))

    yield put(
      setStatusProgressStatus({
        globalStep: PROGRESS_TYPES.PENDING
      })
    )
  } catch (err) {
    const message =
      err.response?.data?.details || err.response?.data?.message || err.message
    console.error(err)
    toast.error(`Erro ao iniciar processamento dos dados: ${message}`, {
      position: toast.POSITION.BOTTOM_RIGHT
    })

    yield put(setErrorGlobalStep(true))

    const accessToken = yield call(getIdToken)
    const consumer = yield call(SQSConsumer.getInstance, { accessToken })
    consumer.stop()
  }
}

export function * fetchGlobalStepNameAndEmpresasSocios (action) {
  try {
    const payload = yield select(state => state.dadosBasicosTheme)
    const dadosBasicos = yield call(fetchDadosBasicosFromS3, { payload })
    const name =
      dadosBasicos.data.dadosBasicos.razaoSocial ||
      dadosBasicos.data.dadosBasicos.nome
    yield put(setTargetNameGlobalStep(name))

    const empresasSocios = Array.from(
      new Map(
        (dadosBasicos.data.empresas.socios || []).map(
          ({ razaoSocial, cnpj }) => [cnpj, { razaoSocial, cnpj }]
        )
      ).values()
    )
    yield put(setEmpresasSociosGlobalStep(empresasSocios))
  } catch (err) {
    console.error(err)
    toast.error('Erro ao capturar nome do target e empresas dos sócios', {
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: 'globalStep-fetch-name-error'
    })
  }
}

const startConsume = async ({ consumer }) => {
  try {
    consumer.consume()
  } catch (err) {
    console.error(err)
  }
}

function resolveInitialState () {
  return Object.fromEntries(
    Object.entries(resolveDictWithLabels()).map(([key, _]) => [key, null])
  )
}
