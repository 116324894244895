import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'

import { FiSearch } from 'react-icons/fi'

import searchDocument from '../../lib/searchDocument'
import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

import API from '../../services/api'

import { getCurrentUser, isCPF } from '../../utils'

import * as styles from './style.module.scss'
import { PRIMARY_COLOR } from '../../config/env'

export default function SubHeader ({ hideSearch, hideTitle }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const [data, setData] = useState({
    documento: ''
  })

  const providers = StaticScopeMetadata.getAvailableProviders()

  const handleSearch = async () => {
    const { documento } = data
    const isCpf = isCPF(documento)

    const hasSerasaPermission = providers.includes(
      EXCLUSIVE_CONTENTS.PROVIDERS.SERASA
    )
    const hasSpreadPermission = providers.includes(
      EXCLUSIVE_CONTENTS.PROVIDERS.SPREAD
    )
    const outputSerasa = hasSerasaPermission
      ? await askSerasa()
      : { isConfirmed: false, isDismissed: false }
    const outputPartners = !isCpf
      ? await askLookForPartners()
      : { isConfirmed: true, isDismissed: false }

    const outputSpread =
      hasSpreadPermission && !isCpf
        ? await haskSpread({ cnpj: documento })
        : { isConfirmed: false, isDismissed: false, value: 'normal' }

    if (
      outputSerasa.isDismissed ||
      outputPartners.isDismissed ||
      outputSpread.isDismissed
    ) {
      return
    }

    const user = await getCurrentUser()

    searchDocument({
      documento,
      spreadType: outputSpread.value,
      enableSerasa: outputSerasa.isConfirmed,
      enablePartners: outputPartners.isConfirmed,
      user,
      dispatch
    })
    const hasFormPermission = StaticScopeMetadata.getAvailablePages().includes(
      EXCLUSIVE_CONTENTS.PAGES.FORMULARIO
    )

    if (hasFormPermission) {
      const basePath =
        StaticScopeMetadata.getAvailableApps().length > 1 ? '/dossie' : ''
      history.push(`${basePath}/formulario`)
    }
  }

  const cleanedPath = location.pathname.replace('/', '')

  return (
    <>
      {cleanedPath !== '' && <Nav />}
      {!hideTitle && (
        <div
          className={`pl-3 mr-3 d-flex flex-row align-items-center ${styles.container}`}
        >
          <div className={`flex-grow-1 ${styles.header}`}>Dossiê 2.0</div>
          <div>
            <button
              className='btn btn-primary mr-2 text-uppercase'
              onClick={() => history.push('/historico')}
            >
              Histórico
            </button>
          </div>
        </div>
      )}
      {!hideSearch && (
        <div
          className={`pl-3 mr-4 mt-2 d-flex flex-row align-items-center justify-content-end ${styles.container}`}
        >
          <div>
            <div className={styles.input}>
              <input
                type='string'
                placeholder='Nova Pesquisa'
                onChange={event =>
                  setData(prev => ({ ...prev, documento: event.target.value }))
                }
                value={data.documento}
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    handleSearch()
                  }
                }}
              />
              <button
                className='btn'
                type='button'
                onClick={() => handleSearch()}
              >
                <FiSearch color={PRIMARY_COLOR} size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const askSerasa = async () => {
  let isConfirmed = false
  let isDismissed = true

  const output = await Swal.fire({
    title: 'Você deseja utilizar as informações do Serasa?',
    text:
      'A consulta que utilize as informações do Serasa possuem um custo adicional que é cobrado com base no número de pessoas relacionadas',
    icon: 'warning',
    showDenyButton: true,
    focusDeny: true, // foca por padrão no botão negativo para que não faça a busca no Serasa se a pessoa digitar o número do documento e apertar ENTER
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#d33',
    confirmButtonText: 'Utilizar',
    denyButtonText: 'Não utilizar'
  })

  isConfirmed = output.isConfirmed
  isDismissed = output.isDismissed

  return {
    isConfirmed,
    isDismissed
  }
}

const askLookForPartners = async () => {
  let isConfirmed = false
  let isDismissed = true

  const output = await Swal.fire({
    title: 'Você deseja buscar familiares dos sócios?',
    text:
      'Ao buscar por familiares dos sócios, você poderá encontrar informações como empresas familiares',
    icon: 'warning',
    showDenyButton: true,
    focusDeny: true, // foca por padrão no botão negativo para que não faça a busca por familiares se a pessoa digitar o número do documento e apertar ENTER
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#d33',
    confirmButtonText: 'Buscar',
    denyButtonText: 'Não buscar'
  })

  isConfirmed = output.isConfirmed
  isDismissed = output.isDismissed

  return {
    isConfirmed,
    isDismissed
  }
}

const haskSpread = async ({ cnpj }) => {
  try {
    Swal.fire({
      title: 'Por favor, aguarde',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })
    const { hasSpread, hasSpreadConsolidado } = await API.spread.exists({
      cnpj
    })
    Swal.hideLoading()
    Swal.close()

    if (hasSpread && !hasSpreadConsolidado) {
      return {
        isConfirmed: true,
        isDismissed: false,
        value: 'normal'
      }
    } else if (!hasSpread && hasSpreadConsolidado) {
      return {
        isConfirmed: true,
        isDismissed: false,
        value: 'consolidado'
      }
    } else if (hasSpread && hasSpreadConsolidado) {
      const { isConfirmed, value } = await Swal.fire({
        title: 'Selecione o tipo de spread',
        text:
          'A empresa possui spread normal e consolidado. Digite "normal" ou "consolidado" para selecionar o tipo de spread que deseja utilizar.',
        input: 'text',
        inputLabel: 'Digite "normal" ou "consolidado" para confirmar',
        confirmButtonText: 'continuar',
        denyButtonText: 'cancelar',
        inputValidator: value => {
          const mappedValue = (value || '').toLowerCase()
          if (!value || !['normal', 'consolidado'].includes(mappedValue)) {
            return 'Você deve digitar "normal" ou "consolidado" para confirmar a ação'
          }
        }
      })

      if (!isConfirmed) {
        return {
          isConfirmed: false,
          isDismissed: true
        }
      }

      return {
        isConfirmed: true,
        isDismissed: false,
        value
      }
    } else {
      return {
        isConfirmed: false,
        isDismissed: false,
        value: 'normal'
      }
    }
  } catch (err) {
    Swal.hideLoading()
    Swal.close()

    console.log(err)
    await Swal.fire({
      title: 'Erro ao verificar o spread',
      text: 'Não foi possível verificar o spread',
      icon: 'error',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK'
    })

    return {
      isConfirmed: false,
      isDismissed: true
    }
  }
}

const Nav = () => {
  const location = useLocation()

  const cleanedPath = location.pathname.replace('/', '')
  const currentPage = !['login', 'historico'].includes(cleanedPath)
    ? 'Pesquisa de Documentos'
    : 'Histórico Pesquisas'

  return (
    <div className={`d-flex flex-row align-items-center ${styles.container}`}>
      <nav aria-label='breadcrumb'>
        <ol
          className='breadcrumb mb-0'
          style={{ backgroundColor: 'transparent' }}
        >
          <li className='breadcrumb-item'>
            <Link to='/'>Dashboard</Link>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            {currentPage}
          </li>
        </ol>
      </nav>
    </div>
  )
}
